<template>
  <div>
       <!-- 创建 -->
        <div style="width:600px;margin:0 auto;background:#fff;">
            <div style="text-align:left;">
                <el-input placeholder="请输入内容" v-model="dynamic.saveData.name" size="small" class="baseInput">
                    <template slot="prepend">名称</template>
                </el-input>
                <el-input placeholder="请输入内容" v-model="dynamic.saveData.number" size="small" class="baseInput">
                    <template slot="prepend">编号</template>
                </el-input>

                <el-input placeholder="请输入内容" v-model="dynamic.saveData.contact_name" size="small" class="baseInput">
                    <template slot="prepend">联系人</template>
                </el-input>
                <el-input placeholder="请输入内容" v-model="dynamic.saveData.phone" size="small" class="baseInput">
                    <template slot="prepend">手机号码</template>
                </el-input>

                <el-input placeholder="请输入内容" v-model="dynamic.saveData.email" size="small" class="baseInput">
                    <template slot="prepend">邮箱</template>
                </el-input>
                <el-input placeholder="请输入内容" v-model="dynamic.saveData.qq" size="small" class="baseInput">
                    <template slot="prepend">qq</template>
                </el-input>
                <el-row>
                    <area-select 
                        :province="dynamic.saveData.province"
                        :city="dynamic.saveData.city"
                        :country="dynamic.saveData.country"
                        @changeData="changeData">
                    </area-select>
                    <el-input placeholder="请输入内容" v-model="dynamic.saveData.address" size="small" class="baseInput" style="width:580px;margin-top:-10px;">
                        <template slot="prepend">地址</template>
                    </el-input>
                </el-row>
                <div class="add_label">
                    门店：<el-select v-model="dynamic.saveData.shop_id" size="small">
                        <el-option v-for="(item,key) in shopList" 
                        :label="item.name"
                        :value="item.id"
                        :key="key"></el-option>
                    </el-select>
                </div>
                <el-switch
                v-model="dynamic.saveData.enable_status"
                active-color="#13ce66"
                inactive-color="#ccc"
                inactive-text="状态">
                </el-switch>
                
                <el-row>
                    <div style="margin:10px 0;">备注：</div>
                    <textarea
                        style="height:100px;border:1px solid #C0C4CC;width:100%;"
                        type="textarea"
                        placeholder="请输入内容"
                        v-model="dynamic.saveData.remarks">
                        </textarea>
                </el-row>
                

                <div slot="footer" class="dialog-footer" style="text-align:center;height:60px;line-height:60px;margin-top:60px;">
                    <el-button  @click="exit();fleshList();">关 闭</el-button>
                    <el-button type="primary" @click="save();">保 存</el-button>
                </div>
            </div> 
        </div>
  </div>
</template>

<script>
import * as dayjs from "dayjs";
import tools from "../../../extends/tools";
import apis from "../../common/js/api2";
import areaSelect from "../../../components/common/Area.vue";

export default {
    props:{
        id:{
            type:Number,
            default:0
        }
    },
    components:{
        areaSelect,
    },
    data(){
        return {
            //用于初始化
            initData:null,
            shopList:[],
            dynamic:{
                saveData:{
                    name:'',
                    number:'',
                    province:0,
                    city:0,
                    country:0,
                    address:'',
                    contact_name:'',
                    phone:'',
                    email:'',
                    qq:'',
                    enable_status:false,
                    shop_id:'',
                    remarks:''
                }
            }
        }
    },
    methods:{
        exit(){
            this.$emit('shareExit')
        },
        fleshList(){
            this.$emit('fleshList')
        },
        changeData(type,parent_name){
            if (type === 2) {
                this.dynamic.saveData.province = parent_name;
            } else if (type === 3) {
                this.dynamic.saveData.city = parent_name;
            } else if (type === 4) {
                this.dynamic.saveData.country = parent_name;
            }
            console.log(this.dynamic.saveData);
        },
        save(){
            let loader = tools.loading(false,this);
            //判断是修改还是新增
            if(this.id > 0){
                this.dynamic.saveData.id = this.id;
                //保存  
                apis.storeSave(this.dynamic.saveData).then((res)=>{
                    if(res.code != 200){
                        this.$message({
                            type:'error',
                            message:res.msg
                        });
                        return;
                    }
                    this.$message({
                        type:'success',
                        message:'保存成功'
                    });
                    //通知列表页更新数据
                    this.fleshList();
                    tools.loading(loader,this);
                }).catch((err)=>{
                    tools.err(err,this);
                    tools.loading(loader,this);
                });                
                
                return;
            }
            //新增
            apis.storeAdd(this.dynamic.saveData).then((res)=>{
                tools.msg(res,this,1);
                //通知列表页更新数据
                this.fleshList();
                tools.loading(loader,this);
            }).catch((err)=>{
                tools.err(err,this);
                tools.loading(loader,this);
            });

        },
    },
    destroyed(){
        this.dynamic = this.initData;
    },
    mounted(){
        if(this.dynamic === null ){
            this.initData = tools.copy(this.dynamic);
        }
        //获取店铺数据源
        apis.getShopSource().then(res=>{
            if(tools.msg(res,this)){
                this.shopList = res.data;
            }
        }).catch(err=>{
            tools.err(err,this);
        });

        //判断是修改还是新增
        if(this.id > 0){
            //初始化修改数据,拉取等级列表
            apis.storeDetail({id:this.id}).then(res=>{
                if(tools.msg(res,this)){
                    this.dynamic.saveData = res.data;
                    this.dynamic.saveData.province = parseInt(this.dynamic.saveData.province);
                    this.dynamic.saveData.city = parseInt(this.dynamic.saveData.city);
                    this.dynamic.saveData.country = parseInt(this.dynamic.saveData.country);
                }
            }).catch(err=>{
                tools.err(err,this);
            });
        }
    }
}
</script>

<style>
  .baseInput{
      width:280px;margin:10px 5px;
  }
  .add_label{
      display:inline-block;margin:10px 0;width:280px;
  }
</style>