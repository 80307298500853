<template>
    <div class="container" :style="{left:selectSide==='left'?'75px':'0',right:selectSide==='right'?'75px':'0'}" >
        <div class="sale" >

<!-- 按钮 -->
                <el-row style="height:40px;">
                    <el-button type="primary" @click="currentId=0;createShareFlag=true" size="small" class="buttons">新增</el-button>
                </el-row>
                <!-- 搜索表单 -->
                <el-form :inline="true" class="demo-form-inline" >
                    <!-- <el-form-item label="状态" >
                        <el-select v-model="req.enable_status" size="small" placeholder="请选择" style="width:120px;">
                            <el-option
                                    v-for="item in statusList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item> -->
                    <el-form-item label="创建时间">
                        <div class="block">
                              <div style="display:inline-block;">
                                <span class="demonstration"></span>
                                <el-date-picker
                                v-model="req.start"
                                size="small"
                                type="date"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd 00:00:00"
                                placeholder="开始时间">
                                </el-date-picker>
                            </div>
                            |&nbsp;&nbsp;&nbsp;&nbsp;
                            <div style="display:inline-block;">
                                <span class="demonstration"></span>
                                <el-date-picker
                                size="small"
                                v-model="req.end"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd 23:59:59"
                                type="date"
                                placeholder="结束时间">
                                </el-date-picker>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <el-input
                        size="small"
                                placeholder="请输入关键词搜索"
                                prefix-icon="el-icon-search"
                                v-model="req.keywords"
                        >
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" size="small" @click="getList">搜索</el-button>
                        <!-- <el-button @click="onReset">重置</el-button> -->
                    </el-form-item>
                </el-form>

            <!-- 顶部导航 -->
            <div class="box-card">
                <!-- 表格 -->
                <el-table
                        ref="multipleTable"
                        :height="__tableHeight__"
        
        
        
                        :data="tableData"
                        stripe
                        tooltip-effect="dark"
                        style="width: 96%;text-align: center">
                    <el-table-column align="center" type="selection" width="65 "> </el-table-column>
                    <el-table-column label="名称"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.name }}</template>
                    </el-table-column>
                    <el-table-column label="仓库编号"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.specification_code}}</template>
                    </el-table-column>
                    <el-table-column label="所属门店"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.shop_info?scope.row.shop_info.name:'' }}</template>
                    </el-table-column>
                    <el-table-column label="联系人"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.contact_name }}</template>
                    </el-table-column>
                    <el-table-column label="手机号码"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.phone }}</template>
                    </el-table-column>
                    <el-table-column label="邮箱"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.email }}</template>
                    </el-table-column>
                    <el-table-column label="状态"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.enable_status }}</template>
                    </el-table-column>
                    <el-table-column label="备注"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.remarks }}</template>
                    </el-table-column>

                    <el-table-column fixed="right" label="操作" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="edit(scope.row.id)" >
                                编辑
                            </el-button>
                            <el-button type="text" size="small"  @click="remove(scope.row.id)">
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>


            </div>
        </div>

        <!-- 创建分享 -->
        <el-dialog
        :modal-append-to-body="false"
        title="新增仓库"
        :visible.sync="createShareFlag"
        v-if="createShareFlag"
        width="700px"
        center>
        <!-- 创建 -->
            <createShare  :id="currentId" @fleshList="getList" @shareExit="shareExit"></createShare>
        </el-dialog>
        <!-- 页码 -->
        <div class="pageDiv" style="">
            <el-pagination
                style="margin:10px 0;"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="req.page"
                :page-size="req.limit"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                class="flex-right">
            </el-pagination>
        </div>
        <!-- 分享列表 -->
        <el-dialog
        :modal-append-to-body="false"
        title="分享列表"
        :visible.sync="shareListFlag"
        v-if="shareListFlag"
        width="80%"
        center>
            <shareList :id="currentId" ></shareList>
        </el-dialog>
    </div>
</template>

<script>
import createShare from './creatDialog';
import shareListFlag from './listDialog';
import apis from "../../common/js/api2";
import tools from "../../../extends/tools";
export default {
props:{

    },
    inject: ['selectSide'], 
    data(){
        return {
            //上传图片列表地址
            saveData:{
                grade_id:'',
                id:''
            },
            createShareFlag:false,
            shareListFlag:false,
            currentId:0,
            tableData:[],
            total:0,
            currentPage:1,
            shareId:0,
            statusList:[],
            req:{
                limit:30,
                page:1,
                keywords:''
            }
        }
    },
    components:{
        createShare,shareListFlag
    },
    methods:{
 // 等级数据保存
        save(){
            const loading = this.$loading({
                lock: true,
                text: '保存数据中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            // 根据currentId判断是否大于0来保存数据
            if(this.currentId>0){
                this.saveData.id = this.currentId;
                // 添加等级
                apis.memberEdit(this.saveData).then(res=>{
                    tools.msg(res,this,1);
                    loading.close();
                }).catch(err=>{
                    tools.err(err,this);
                    loading.close();
                });
                return;
            }
        },
        handleSizeChange(val) {
            this.req.limit = val;
            this.getList();
        },
        handleCurrentChange(val) {
            this.req.page = val;
            this.getList();
        },
        getList(){
            apis.storeList(this.req).then((res) => {
                this.tableData = res.data.data;
                this.total = res.data.total;
            }).catch((err) => {
                this.$message('网络或程序错误，请稍候重试！', 'error');
            });
        },
        exit(){
            this.$emit('shareExit')
        },
        shareExit(){
            this.createShareFlag = false;
            this.shareListFlag = false;
        },
        remove(id, key) {
            this.$confirm('确定删除此记录?', '删除确认', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                apis.storeDelete({ id: id}).then((info) => {
                    if (info.code == 200) {
                        this.tableData.splice(this.tableData.findIndex(item => item.id === id), 1);
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: info.msg
                        });
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        edit(id){
            this.createShareFlag = true;
            this.currentId = id;
        }
    },
    created(){
        // 获取数据源列表   
        apis.levelList({
            type:'1'
        }).then(res=>{
            if(tools.msg(res,this)){
                this.typeSource = res.data.data;
            }
        }).catch(err=>{
            tools.err(err,this);
        });

        this.getList();
    }
}
</script>

<style scoped lang="less">
@import url('../../../assets/less/normal');
.filexls {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  top: 0px;
  left: 0px;
  opacity: 0;
  cursor: pointer;
}
</style>